<template>
  <div class="box_item info_cont">
    <div v-if="Info.is_prepared != 0">
      <div class="flexSb list">
        <div class="flexSt">
          <div class="title">备课名称 :</div>
          <el-input disabled v-model="form.prepare_name"></el-input>
        </div>
        <div class="flexSt">
          <div class="title">运动项目 :</div>
          <el-input disabled v-model="form.project_namelist"></el-input>
        </div>
      </div>
      <div class="flexSb list">
        <div class="flexSt">
          <div class="title">教学设备 :</div>
          <el-input disabled v-model="form.device_name"></el-input>
        </div>
      </div>

      <div class="flexSt list2 list">
        <div class="title">教案 :</div>
        <div class="table">
          <el-table
            ref="multipleTable"
            :data="form.teaching_plan"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="tableHeaderColor"
            :header-cell-class-name="cellClass"
            :cell-class-name="returnName"
          >
            <el-table-column
              type="index"
              label="序号"
              width="60"
              align="center"
            >
            </el-table-column>

            <el-table-column min-width="90" label="名称" align="center">
              <template slot-scope="scope">
                <div class="name">{{ scope.row.name }}</div>
              </template>
            </el-table-column>
            <el-table-column width="80" label="大小" align="center">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.filesize
                      ? (scope.row.filesize / 1024 / 1024).toFixed(2)
                      : 0
                  }}MB
                </div>
              </template>
            </el-table-column>
            <el-table-column label="上传时间" min-width="120" align="center">
              <template slot-scope="scope">
                <div class="flexSc">{{ scope.row.create_time }}</div>
              </template>
            </el-table-column>
            <el-table-column
              width="120"
              fixed="right"
              label="操作"
              align="center"
            >
              <template slot-scope="scope">
                <div class="flexSc">
                  <div @click="handleShow(scope.row.path)" class="btn">
                    查看
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <div class="flexSt list2 list">
        <div class="title">资源 :</div>
        <div class="table">
          <el-table
            ref="multipleTable"
            :data="form.resources"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="tableHeaderColor"
            :header-cell-class-name="cellClass"
            :cell-class-name="returnName"
          >
            <el-table-column
              type="index"
              label="序号"
              width="60"
              align="center"
            >
            </el-table-column>

            <el-table-column min-width="90" label="名称" align="center">
              <template slot-scope="scope">
                <div class="name">{{ scope.row.name }}</div>
              </template>
            </el-table-column>
            <el-table-column width="80" label="大小" align="center">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.filesize
                      ? (scope.row.filesize / 1024 / 1024).toFixed(2)
                      : 0
                  }}MB
                </div>
              </template>
            </el-table-column>
            <el-table-column label="引用时间" min-width="120" align="center">
              <template slot-scope="scope">
                <div class="flexSc">{{ scope.row.create_time }}</div>
              </template>
            </el-table-column>
            <el-table-column
              width="120"
              fixed="right"
              label="操作"
              align="center"
            >
              <template slot-scope="scope">
                <div class="flexSc">
                  <div @click="handleShow(scope.row.path)" class="btn">
                    查看
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <el-empty v-else :image-size="200"></el-empty>
  </div>
</template>
<script>
import { courseLogprepare, elegantMoment } from "@/api/classroon/record";
export default {
  data() {
    return {
      tableData: [],
      Info: {
        id: "",
      },
      form: {
        prepare_name: "",
        project_namelist: "",
        project_name: [],
        device_name: "",
        device: "",
        teaching_plan: [],
        resources: [],
      },
    };
  },
  computed: {
    indexMethod() {
      // 当前页数 - 1 * 每页数据条数 + 1
      //   return (this.pageInfo.page - 1) * this.pageInfo.pageSize + 1;
    },
  },
  mounted() {
    if (this.$route.query.val) {
      this.Info = JSON.parse(this.$route.query.val);
      let val = this.Info;
      console.log(val);
      this.Info.showtime = val.date + val.time;
      this.Info.course_typeName =
        val.course_type == 1 ? "常规体育课" : "大课间";
      this.handlecourseLogprepare();
    }
  },
  methods: {
    handleShow(val) {
      window.open(val);
    },
    async handlecourseLogprepare() {
      let data = {
        id: this.Info.id,
      };
      await courseLogprepare(data).then((res) => {
        console.log(res.data);
        this.form = res.data;
        this.form.project_namelist =
          this.form.project_name.length > 0
            ? this.form.project_name.join("、")
            : "无";
        this.form.device_name =
          this.form.device.length > 0 ? this.form.device.join("、") : "无";
      });
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      return {
        fontSize: "13px",
        color: "rgb(0,12,22)",
        paddingTop: "8px",
        paddingBottom: "8px",
        backgroundColor: "#C7E1FF",
      };
    },
    cellClass() {
      return "disableSelection";
    },
    returnName(obj) {
      if (obj.columnIndex == 0) {
        return "firstCell";
      }
    },
  },
};
</script>
<style lang="less" scoped>
.info_cont {
  padding: 0 80px;
  box-sizing: border-box;
  .list {
    margin-top: 20px;
  }
  .title {
    min-width: 70px;
    color: rgba(16, 16, 16, 1);
    font-size: 14px;
    text-align: right;
  }
  .el-input {
    width: 330px;
    margin-left: 9px;
    // margin-right: 10px;
  }
  /deep/ .el-input.is-disabled .el-input__inner {
    color: rgba(16, 16, 16, 1);
  }
  .list2 {
    width: 100%;
    align-items: flex-start;
    .title {
      margin-top: 10px;
    }
    .table {
      margin-left: 9px;
      flex: 1;
      /deep/ .el-table td.el-table__cell,
      /deep/.el-table th.el-table__cell.is-leaf {
        border-bottom: 1px solid #fff;
      }
      ::-webkit-scrollbar {
        width: 10px !important;
        height: 10px !important;
        // background-color: #ccc !important;
      }
      ::-webkit-scrollbar-track {
        // background-color: #bbbbbb;
      }
      /* 滚动条上的滚动滑块 */
      ::-webkit-scrollbar-thumb {
        background-color: #ccc;
      }
      /deep/.el-table {
        tr {
          background-color: #c7e1ff;
        }

        .cell {
          color: rgba(16, 16, 16, 1);
          font-size: 14px;
        }
      }
      /deep/.el-table__body tr.hover-row > td.el-table__cell {
        background-color: #c7e1ff;
      }
      .name {
        color: rgb(4, 101, 249);
      }
      .btn {
        width: 44px;
        height: 22px;
        line-height: 20px;
        border-radius: 4px;
        background-color: rgba(255, 255, 255, 1);
        color: rgba(24, 144, 255, 1);
        font-size: 14px;
        text-align: center;
        font-family: Microsoft Yahei;
        border: 1px solid rgba(24, 144, 255, 1);
        cursor: pointer;
      }
    }
  }
}
</style>
