import request from "@/utils/request";
import { baseUrl } from "@/utils/config";

// 课程记录列表
export function courseLog(data) {
  return request({
    url: baseUrl + "/courseLog",
    method: "get",
    params: data,
  });
}
// 年级列表
export function getGradelist(data) {
  return request({
    url: baseUrl + "/course/grade",
    method: "get",
    params: data,
  });
}

// 班级列表
export function getClasslist(data) {
  return request({
    url: baseUrl + "/course/class",
    method: "get",
    params: data,
  });
}

// 备课情况
export function courseLogprepare(data) {
  return request({
    url: baseUrl + "/courseLog/prepare",
    method: "get",
    params: data,
  });
}

// 课堂风采
export function elegantMoment(data) {
  return request({
    url: baseUrl + "/courseLog/elegantMoment",
    method: "get",
    params: data,
  });
}
